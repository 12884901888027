<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <a-button icon="plus" type="primary" @click="onAdd" v-show="isShowAdd">新建海报</a-button>
                    <div class="table-operator">
                        <a-select ref="select" v-model="status" placeholder="请选择" style="width: 120px">
                            <a-select-option :value="-1">全部</a-select-option>
                            <a-select-option :value="2">已上架</a-select-option>
                            <a-select-option :value="3">待上架</a-select-option>
                        </a-select>
                        <a-input v-model="name" style="width:150px;margin:0 20px" placeholder="请输入海报名称" />
                        <a-button type="primary" @click="search">搜索</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.Id"  :loading="tableLoading" :pagination="false">
                        <span slot="number" slot-scope="number, record, index">
                            <span>{{ index + 1 }}</span>
                        </span>
                        <img v-viewer class="avatar" slot="ImageUrl" slot-scope="ImageUrl, record" :src="record.ImageUrl" alt="海报"/>
                        <span slot="PutawayFlag" slot-scope="PutawayFlag, record">
                            <a-badge v-show="record.PutawayFlag == 2" status="success" text="已上架"/>
                            <a-badge v-show="record.PutawayFlag == 3" status="warning" text="待上架"/>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <template>
                                <a class="margin_right10" v-if="record.PutawayFlag == 2" @click="releaseStatusConfirm(record)">下架</a>
                                <a class="margin_right10" v-else @click="releaseStatusConfirm(record)">上架</a>
                                <a v-show="isShowUpd" class="btn-edit" @click="onEdit(record)">编辑</a>
                            </template>
                        </span>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-if="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import {
    GetBannerApi,
    updateBannerStatusApi,
    delBannerApi,
    PlaybillList, SavePutawayFlag,
} from "@/request/api/indexManage";

export default {
    components: {MyPagination},
    created() {
        if (codeFn("addCategory")) this.isShowAdd = true;
        if (codeFn("/admin/course/label/updLabel")) this.isShowUpd = true;
        if (codeFn("/admin/course/label/delLabel")) this.isShowDel = true;
    },
    data() {
        return {
            status:-1,
            name:'',
            spinning: false, // 全局loading
            columns: [
                {
                    title: "序号",
                    dataIndex: "number",
                    key: "number",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "海报图片",
                    dataIndex: "ImageUrl",
                    key: "ImageUrl",
                    scopedSlots: {customRender: "ImageUrl"},
                },
                {
                    title: "名称",
                    dataIndex: "Name",
                    key: "Name",
                },
                {
                    title: "状态",
                    dataIndex: "PutawayFlag",
                    key: "PutawayFlag",
                    scopedSlots: {customRender: "PutawayFlag"},
                },
                {
                    title: "创建时间",
                    dataIndex: "CreateTime",
                    key: "CreateTime",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "180px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            tableLoading: false,
            confirmLoading: false,
            isShowAdd: false, // 修改是否隐藏
            isShowUpd: false, // 新增是否隐藏
            isShowDel: false, // 删除是否隐藏
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0// 列表的总条数
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {
        search(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.getTagList();
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            PlaybillList({
                name:this.name,
                putawayFlag:this.status == undefined ? '' : this.status,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableLoading = false
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
            });
        },

        // 发布/取消发布弹窗
        releaseStatusConfirm(record) {
            let self = this
            const text = record.PutawayFlag === 2 ? '下架' : '上架';
            Modal.confirm({
                title: "提示",
                content: `确认${text}吗`,
                okText: "确认",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    SavePutawayFlag({
                        id: record.Id,
                        putawayFlag:record.PutawayFlag == 2 ? 0 : 2
                    }).then(({code, msg}) => {
                        if (code === 200) {
                            self.getTagList();
                        } else {
                            message.error(msg || "操作失败，请稍后重试");
                        }
                    });
                }
            });
        },

        // 添加
        onAdd() {
            this.$router.push("/indexManage/playbill/PlaybillEdit/0");
        },

        // 编辑
        onEdit(record) {
            this.$router.push(`/indexManage/playbill/PlaybillEdit/${record.Id}`);
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    margin-bottom: 30px;
}

.avatar {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
</style>
